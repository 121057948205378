import { FETCH_USER_DETAILS, FETCH_LOGOUT, FETCH_FAILURE } from "./userTypes";
const initialState = {
  login: false,
  userEmail: "",
  userName: "",
  userId: "",
  authorized: true,
};
const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_DETAILS: {
      return {
        ...state,
        userEmail: action.payload.email,
        userName: action.payload.name,
        userId: action.payload.userId,
        authorized: true,
        login: true,
      };
    }
    case FETCH_LOGOUT:
      console.log("FETCH_LOGOUT");
      return {
        ...state,
        login: false,
        authorized: false,
      };
    case FETCH_FAILURE:
      console.log("FETCH_LOGOUT");
      return {
        ...state,
        authorized: false,
        login: false,
      };
    default:
      return state;
  }
};
export default userReducer;
