import React, { useState, useEffect } from "react";
// material-ui
import { Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "react-redux";
import { Grid } from "@mui/material";
import { gridSpacing } from "store/constant";
import { useNavigate } from "react-router-dom";
// project imports
import MainCard from "ui-component/cards/MainCard";
import { SET_SIDE_MENU } from "store/actions";
import AdvertiserCard from "../views/dashboard/Default/AdvertiserCard";
import { fetchAdvertiserList } from "../redux/advertiser/advertiserActions";
import { fetchUserDetails } from "../redux/user/userActions";
import LoaderCircular from "ui-component/LoaderCircular";
import LoaderCustom from "ui-component/LoaderCustom";
import ReactGA from 'react-ga4';
// ==============================|| SAMPLE PAGE ||============================== //

const Advertisers = ({
  fetchAdvertiserList,
  advertiserList,
  login,
  userEmail,
  user_not_onboarded,
}) => {
  ReactGA.send({ hitType: "pageview", page: "/advertisers", title: "Advertisers" });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    //console.log(login)

    if (login == true && userEmail != "") {
      setLoader(true);
      fetchAdvertiserList(userEmail);
    }

    dispatch({ type: SET_SIDE_MENU, showSideMenu: false });
    localStorage.setItem("showSideMenu", false);
  }, [login]);
  useEffect(() => {

    console.log(
      "inside advertiser page--->",
      advertiserList,
      user_not_onboarded,
    );

    if (advertiserList.length > 0) {
      setLoader(false);
    }
    if (user_not_onboarded == true) {
      navigate("/", { replace: true });
    }

    // fetchUserDetails()
  }, [advertiserList, user_not_onboarded]);


  console.log(advertiserList);
  return (
    <>
      {advertiserList.length == 0 ? (
        <LoaderCustom />
      ) : (
        <>
          {" "}
          <MainCard title="Advertiser List">
            <Grid container spacing={gridSpacing}>
              {advertiserList.length ? (
                advertiserList.map((advertiser) => (
                  <Grid
                    item
                    xs={4}
                    sm={4}
                    md={4}
                    lg={3}
                    xl={4}
                    key={advertiser.advertiser_guid}
                  >
                    <AdvertiserCard data={advertiser} />
                  </Grid>
                ))
              ) : (
                <div>No advertiser Mapped</div>
              )}
            </Grid>
          </MainCard>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.advertiserReducer.loading,
    advertiserList: state.advertiserReducer.advertiser_list,
    login: state.userReducer.login,
    userEmail: state.userReducer.userEmail,
    user_not_onboarded: state.advertiserReducer.user_not_onboarded,
   
  };
};
const mapDispatchToProps = {
  fetchAdvertiserList: fetchAdvertiserList,
  fetchUserDetails: fetchUserDetails,
};
export default connect(mapStateToProps, mapDispatchToProps)(Advertisers);
