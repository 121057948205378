import axios from  "axios";
import {FETCH_CREATIVE_DATA,FETCH_CREATIVE_REQUEST,FETCH_CREATIVE_ERROR,RESET_CREATIVE_VIEW_DATA} from './creativeTypes'
const timeout = 120000;

export const fetchCreativeReportRequest=()=>{
    return {
        type:FETCH_CREATIVE_REQUEST,
        payload:[]
    }
}
export const fetchCreativeReportFailure = (error) => {
    return {
      type: FETCH_CREATIVE_ERROR,
      payload: error,
    };
};
export const resetCreativeData=()=>{
  return {
    type:RESET_CREATIVE_VIEW_DATA,
    payload:[]
  }
}
export const fetchCreativeDataNonSkan =
  (campaignList) => (dispatch, getState) => {
    console.log("process fetching data");
    dispatch(fetchCreativeReportRequest());
    axios({
      method: "post",
      url: `${process.env.REACT_APP_INTERNAL_SERVER_URL}/getNonSkanCreativeReport`,
      header: {
        "Content-Type": "application/json",
      },
      data: {
        userEmail: getState().userReducer.userEmail,
        CampaignIds: campaignList,
        advertiser_id: localStorage.getItem("advertiserId"),
        sfId: localStorage.getItem("sfId"),
        startDate: getState().campaignReducer.start_date,
        endDate: getState().campaignReducer.end_date,
      },
      timeout: timeout
    })
      .then((response) => {
        
        if (response.data.length > 0) {
          console.log(response.data)
          return dispatch(fetchDataForCreative(response.data[0].creativedata));
        } else {
          return dispatch(fetchBundleReportFailure);
        }
      })
      .catch(function (error) {
       
        return dispatch(fetchCreativeReportFailure("Not able to fetch data. Please retry"));
        //return Promise.reject(error)
      });
};
export const fetchCreativeDataSkan =
  (campaignList) => (dispatch, getState) => {
    console.log("process fetching data");
    dispatch(fetchCreativeReportRequest());
    axios({
      method: "post",
      url: `${process.env.REACT_APP_INTERNAL_SERVER_URL}/getSkanCreativeReport`,
      header: {
        "Content-Type": "application/json",
      },
      data: {
        userEmail: getState().userReducer.userEmail,
        CampaignIds: campaignList,
        advertiser_id: localStorage.getItem("advertiserId"),
        sfId: localStorage.getItem("sfId"),
        startDate: getState().campaignReducer.start_date,
        endDate: getState().campaignReducer.end_date,
      },
      timeout: timeout
    })
      .then((response) => {
        
        if (response.data.length > 0) {
          console.log(response.data)
          return dispatch(fetchDataForCreative(response.data[0].creativedata));
        } else {
          return dispatch(fetchBundleReportFailure);
        }
      })
      .catch(function (error) {
        console.log(">>>>>>>>>>>>",error.response.data.statusCode)
        if(error.response.data.statusCode==404){
          dispatch(fetchCreativeReportFailure("No Data Found"))
        }
        else{
          return dispatch(fetchCreativeReportFailure("Not able to fetch data. Please retry"));
        }
       
        //return Promise.reject(error)
      });
};
function detectKeys(jsonData) {
    const keys = new Set();
  
    jsonData.forEach((obj) => {
      Object.keys(obj).forEach((key) => {
        keys.add(key);
      });
    });
    return Array.from(keys);
}
export const fetchDataForCreative = (data) => {
    // Call the function with your JSON data
    const detectedKeys = detectKeys(data);
    //console.log("detectedKeys",detectedKeys)
    const creativeDetails = data.map((item, id = index) => ({ id, ...item }));
    return {
      type: FETCH_CREATIVE_DATA,
      payload: creativeDetails,
    };
  };