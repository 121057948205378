import React, { useState, useEffect } from "react";
import { useDispatch, connect } from "react-redux";
import { gridDataRowIdsSelector } from "@mui/x-data-grid";
import {
  Avatar,
  Button,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Typography,
  Toolbar,
  CssBaseline,
  AppBar,
  Input,
  Select,
  TextField,
  Autocomplete,
  Checkbox,
  Box,
  FormGroup,
} from "@mui/material";
import { startOfYesterday, format, addDays } from "date-fns";
import { DateRangePicker } from "react-date-range";
import IconButton from "@mui/material/IconButton";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import HorizontalRuleTwoToneIcon from "@mui/icons-material/HorizontalRuleTwoTone";
import Switch from "@mui/material/Switch";
import "./styleSearch.scss";
import { SET_SEARCH_DATES } from "../../redux/campaign/campaignTypes";

const DateSelector = ({
  advertiser_campaign_fetching,
  performanceLoading,
  widzardLoading,
  reportLoading,
  advertiser_guid
}) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [startDate, setStartDate] = useState(addDays(new Date(), -2));
  const [endDate, setEndDate] = useState(addDays(new Date(), -1)); //useState(startOfYesterday() );
  const [selectionCount, setSelectionCount] = useState(0);
  const [disable, setDisable] = useState(false);
  const [minimumDate,setMinimumDate]=useState(new Date(2023, 12, 1))
  const dispatch = useDispatch();
  const getFormattedDate = (date) => {
    return format(date, "dd/MMM/yy");
  };
  const getFormattedDateForAPI = (date) => {
    return format(date, "yyyy-MM-dd");
  };
  const handleSelectRange = (ranges) => {
    const count = selectionCount + 1;
    setSelectionCount(count);
    setStartDate(ranges.selection.startDate);
    setEndDate(ranges.selection.endDate);
    if (count === 2)
      getFilteredData(ranges.selection.startDate, ranges.selection.endDate);
  };
  const getFilteredData = (startDate, endDate) => {
    setSelectionCount(0);
    setShowCalendar(!showCalendar);
    dispatch({
      type: SET_SEARCH_DATES,
      payload: {
        startDate: getFormattedDateForAPI(startDate),
        endDate: getFormattedDateForAPI(endDate),
      },
    });
  };
  useEffect(() => {
    if (selectionCount == 0) {
      //console.log("selectionCount",selectionCount,startDate,endDate)
      dispatch({
        type: SET_SEARCH_DATES,
        payload: {
          startDate: getFormattedDateForAPI(startDate),
          endDate: getFormattedDateForAPI(endDate),
        },
      });
    }
  }, []);
  useEffect(() => {
    console.log(
      "advertiser_campaign_fetching",
      advertiser_campaign_fetching,
      "performanceLoading",
      performanceLoading,
      "widzardLoading",
      widzardLoading,
      "reportLoading",
      reportLoading,
    );
    // if(advertiser_campaign_fetching==true || performanceLoading==true || widzardLoading==true){
    //     setDisable(true)
    // }
    // else{
    //     setDisable(false)
    // }
  }, [advertiser_campaign_fetching, performanceLoading]);
  useEffect(()=>{
    console.log("advertiser_guid",advertiser_guid)
    if(advertiser_guid=="f1d0596e76624886add7a5ec83662809"){
      console.log(new Date(2024, 8, 1))
      setMinimumDate(new Date(2024, 8, 1))
    }
    else{
      setMinimumDate(new Date(2023, 12, 1))
    }
  },[advertiser_guid])
  return (
    <div className="date-filter">
      {/* <div  onClick={()=>handleLogin1()}>
                        <div>{getFormattedDate(startDate)}-{getFormattedDate(endDate)}</div>
                   
                        <CalendarMonthIcon fontSize="small" className="calendar-logo" />
                    </div> */}
      <div className={disable ? "calendarDisable" : "calendarEnable"}>
        <Box
          className="selected-date blue-font flex-row justify-around"
          onClick={setShowCalendar}
          sx={{ width: "100px", height: "40px" }}
        >
          <div className="selectedDateRangeInputBox">
            {getFormattedDate(startDate)}{" "}
            <HorizontalRuleTwoToneIcon
              fontSize="medium"
              sx={{ color: "rgb(13 13 13)" }}
            />{" "}
            {getFormattedDate(endDate)}
          </div>

          <CalendarMonthIcon
            fontSize="medium"
            className="calendar-logo"
            sx={{ color: "rgb(0, 143, 251)" }}
          />
        </Box>
        <div className="dateBoxContainer">
          <DateRangePicker
            className={`calendar ${showCalendar ? "show" : "hide"}`}
            ranges={[
              {
                startDate: startDate,
                endDate: endDate,
                key: "selection",
              },
            ]}
            calendarFocus="backwards"
            // minDate={new Date(2024, 8, 1)}
            minDate={minimumDate}
            maxDate={startOfYesterday()}
            onChange={handleSelectRange}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={2}
            direction="horizontal"
            preventSnapRefocus={true}
          />
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    advertiser_campaign_fetching:
      state.advertiserReducer.advertiser_campaign_fetching,
    performanceLoading: state.campaignReducer.performanceLoading,
    widzardLoading: state.campaignReducer.widzardLoading,
    reportLoading: state.campaignReducer.reportLoading,
    advertiser_guid: state.advertiserReducer.advertiser_guid,
  };
};
export default connect(mapStateToProps, null)(DateSelector);
