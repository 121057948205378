import React, { useState, useEffect } from "react";
import { gridSpacing } from "store/constant";
import { styled, useTheme } from "@mui/material/styles";
import { useHistory, useLocation, useNavigate } from "react-router-dom";
import {
  Avatar,
  Button,
} from "@mui/material";
import { connect, useDispatch } from "react-redux";
import "./styleSearch.scss";
import {
  fetchAdvertiserList,
  setSelectedAdvertiser,
  fetchCampaignsByAdvertiserGuid,
  fetchCampaignsForAdvertiser,
  fetchSkanCampaignsForAdvertiser,
} from "../../redux/advertiser/advertiserActions";

import {
  applyFilter,
  fetchDashboardDataNonSkan,
  fetchDashboardDataSkan
} from "../../redux/campaign/campaignActions";
import {
  fetchReportDataForNonSkanReporting,
  fetchReportDataForSkanReporting,
} from "../../redux/reporting/reportingActions";
import {
  fetchCreativeDataNonSkan,
  fetchCreativeDataSkan
} from "../../redux/creative/creativeActions"
const ApplyFilterPage = ({
  isSkan,
  selected_campaigns,
  applyFilter,
  applyFilterStatus,
  fetchReportDataForNonSkanReporting,
  fetchReportDataForSkanReporting,
  firstFilter,
  fetchDashboardDataNonSkan,
  fetchDashboardDataSkan,
  fetchCreativeDataNonSkan,
  fetchCreativeDataSkan
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  let currentPath = location.pathname.split("/")[1];
  console.log(currentPath);
  const [filterClick, setFilterClick] = useState();
  const [disableFilterButton, setDisableFilterButton] = useState(false);
  const [firstLoad,setFirstLoad]=useState(firstFilter);
  const applyFilterButton = (e) => {
    console.log("apply filter",e)
    //setFilterClick((prevClicked) => !prevClicked);
    setFirstLoad(1)
    setFilterClick(true);
    applyFilter(true);
    setTimeout(() => {
      setFilterClick(false);
      applyFilter(false);
    }, 1000);
    // let advertiser_guid=localStorage.getItem('advertiserId')
    // console.log(advertiser_guid)
  };
  useEffect(() => {
    console.log("status-->", applyFilterStatus, selected_campaigns,firstLoad,currentPath);

    if (
      applyFilterStatus == true &&
      selected_campaigns.length > 0 &&
      currentPath == "dashboard"
    ) {console.log("Dashboard--->>>>>")
      if (isSkan == true) {
        fetchDashboardDataSkan(selected_campaigns)
       
      } else {
        fetchDashboardDataNonSkan(selected_campaigns)
       
      }
      setDisableFilterButton(false);
    } else if (
      currentPath == "reporting" &&
      applyFilterStatus == true &&
      selected_campaigns.length > 0 && filterClick!=undefined
    ) { console.log("Reporting--->>>>>")
      if (isSkan == true) {
        fetchReportDataForSkanReporting(selected_campaigns);
      } else {
        fetchReportDataForNonSkanReporting(selected_campaigns);
        
      }
    }
    else if (
      currentPath == "creative" &&
      applyFilterStatus == true &&
      selected_campaigns.length > 0 && filterClick!=undefined
    ) {
      console.log("Creative--->>>>>")
      if (isSkan == true) {
        fetchCreativeDataSkan(selected_campaigns);
      } else {
        fetchCreativeDataNonSkan(selected_campaigns);
        
      }
    }
    else {
      setDisableFilterButton(true);
    }
  }, [applyFilterStatus]);
  useEffect(() => {

    console.log("status", filterClick,firstLoad);

    if (filterClick == undefined && selected_campaigns.length > 0 && firstLoad==0 &&  currentPath != "reporting" &&  currentPath != "creative") {
      if (isSkan == true) {
        fetchDashboardDataSkan(selected_campaigns)
      } else {
        fetchDashboardDataNonSkan(selected_campaigns)
      }
      setFilterClick(true);
      applyFilter(true)
      setTimeout(() => {
        setFilterClick(false);
        applyFilter(false)
      }, 1000);
    }
    setDisableFilterButton(false);
  }, [filterClick, selected_campaigns]);
  return (
    <div className="filter-Button">
      <Button
        variant="outlined"
        onClick={applyFilterButton}
        disabled={disableFilterButton}
      >
        Apply Filter
      </Button>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    selected_campaigns: state.advertiserReducer.selected_campaigns,
    advertiser_campaign_fetching:
      state.advertiserReducer.advertiser_campaign_fetching,
    performanceLoading: state.campaignReducer.performanceLoading,
    widzardLoading: state.campaignReducer.widzardLoading,
    reportLoading: state.campaignReducer.reportLoading,
    isSkan: state.campaignReducer.isSkan,
    applyFilterStatus: state.campaignReducer.applyFilter,
    firstFilter:state.campaignReducer.firstFilter
  };
};
const mapDispatchToProps = {
  setSelectedAdvertiser: setSelectedAdvertiser,
  fetchCampaignsByAdvertiserGuid: fetchCampaignsByAdvertiserGuid,
  fetchCampaignsForAdvertiser: fetchCampaignsForAdvertiser,
  fetchSkanCampaignsForAdvertiser: fetchSkanCampaignsForAdvertiser,
  applyFilter: applyFilter,
  fetchReportDataForNonSkanReporting: fetchReportDataForNonSkanReporting,
  fetchReportDataForSkanReporting: fetchReportDataForSkanReporting,
  fetchDashboardDataNonSkan: fetchDashboardDataNonSkan,
  fetchDashboardDataSkan: fetchDashboardDataSkan,
  fetchCreativeDataNonSkan: fetchCreativeDataNonSkan,
  fetchCreativeDataSkan: fetchCreativeDataSkan
};
export default connect(mapStateToProps, mapDispatchToProps)(ApplyFilterPage);
