import { combineReducers } from "redux";
import customization from "../store/customizationReducer";
import skanRateCardStoreReducer from "./skanRateCardStore/skanRateCardStoreReducer";
import advertiserReducer from "./advertiser/advertiserReducer";
import campaignReducer from "./campaign/campaignReducer";
import userReducer from "./user/userReducer";
import reportingReducer from "./reporting/reportingReducer";
import creativeReducer from "./creative/creativeReducer";
const reducer = combineReducers({
  customization,
  skanRateCardStoreReducer,
  advertiserReducer,
  campaignReducer,
  userReducer,
  reportingReducer,
  creativeReducer
});

export default reducer;
