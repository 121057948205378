export const FETCH_REQUEST = "FETCH_REQUEST";
export const FETCH_ADVERTISER_LIST = "FETCH_ADVERTISER_LIST";
export const FETCH_CAMPAIGN_LIST = "FETCH_CAMPAIGN_LIST";
export const FETCH_ERROR = "FETCH_ERROR";
export const FETCH_SUCCESS = "FETCH_SUCCESS";
export const SET_ADVERTISER = "SET_ADVERTISER";
export const FETCH_CAMPAIGN_LIST_BY_ADVERTISER =
  "FETCH_CAMPAIGN_LIST_BY_ADVERTISER";
export const SET_CAMPAIGN_LIST_FOR_REPORTING =
  "SET_CAMPAIGN_LIST_FOR_REPORTING";
export const SET_CAMPIGN_LIST_SELECTED = "SET_CAMPIGN_LIST_SELECTED";
export const FETCH_ADVERTISER_REQUEST = "FETCH_ADVERTISER_REQUEST";
export const FETCH_CAMPAIGN_ERROR = "FETCH_CAMPAIGN_ERROR";
export const FETCH_ADVERTISER_ERROR = "FETCH_ADVERTISER_ERROR";
