import {FETCH_CREATIVE_DATA,FETCH_CREATIVE_REQUEST,FETCH_CREATIVE_ERROR,RESET_CREATIVE_VIEW_DATA} from './creativeTypes'

const initialState={
    creativeData:[],
    creativeLoading:false,
    error:''
}
const creativeReducer=(state=initialState,action)=>{
    switch(action.type){
        case FETCH_CREATIVE_REQUEST:{
            return{
                ...state,
                creativeData:action.payload,
                creativeLoading:true,
                error:''
            }
        }
        case FETCH_CREATIVE_ERROR:{
            return {
              ...state,
              loading: false,
              creativeData: [],
              creativeLoading:false,
              error:action.payload
            };
        }
        case FETCH_CREATIVE_DATA:{
            return {
              ...state,
              creativeData: action.payload,
              creativeLoading:false,
              error:''
            };
        }
        case RESET_CREATIVE_VIEW_DATA:{
            return {
                ...state,
                creativeData:[],
                error:''
            }
        }
        default:
            return state
    }
}
export default creativeReducer;