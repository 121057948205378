import axios from "axios";
import { FETCH_USER_DETAILS, FETCH_FAILURE, FETCH_LOGOUT } from "./userTypes";
export const fetchFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    payload: [],
  };
};
export const fetchLogout = (error) => {
  // console.log("fetch logout");
  return {
    type: FETCH_LOGOUT,
    payload: error,
  };
};
export const userDetailsFetched = (details) => {
  //console.log(details);

  return {
    type: FETCH_USER_DETAILS,
    payload: details.data,
  };
};
export const getCookie = (cname) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};
export const deleteCookieByName = (name) => {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};
export const fetchUserDetails = () => (dispatch, getState) => {
  
  axios({
    method: "get",
    url: `${process.env.REACT_APP_INTERNAL_SERVER_URL}/userDetails`,

    headers: {
      "Content-Type": "application/json",
      // userToken: getCookie("_im_auth_token"),//For staging
      userToken: getCookie("_prod_im_auth_token"),// For Prod
    },
  })
    .then((response) => {
     
      if (response.data.httpCode == 401) {
        return dispatch(fetchFailure(response.data));
      }
      return dispatch(userDetailsFetched(response.data));
    })
    .catch((err) => {
      
      return dispatch(fetchFailure("logout"));
    });
};

export const logoutUser = () => (dispatch) => {
  // deleteCookieByName("_im_auth_token")//staging
  deleteCookieByName("_prod_im_auth_token")
  return dispatch(fetchLogout("logout"));
};
