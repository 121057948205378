// material-ui
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";
// styles
const LoaderWrapper = styled("div")({
  position: "relative",
  top: 0,
  left: 0,
  zIndex: 1,
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
});

// ==============================|| LOADER ||============================== //
const LoaderCircular = () => (
  <LoaderWrapper>
    <CircularProgress color="primary" />
  </LoaderWrapper>
);

export default LoaderCircular;
