import { useState } from "react";
import { startOfYesterday, format, addDays } from "date-fns";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

const DateSelectorV1 = () => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [startDate, setStartDate] = useState(startOfYesterday());
  const [endDate, setEndDate] = useState(addDays(new Date(), 7));
  const [selectionCount, setSelectionCount] = useState(0);
  const handleSelectRange = (ranges) => {
    const count = selectionCount + 1;
    setSelectionCount(count);
    setStartDate(ranges.selection.startDate);
    setEndDate(ranges.selection.endDate);
    console.log("count", count);
    // if(count===2) getFilteredData(ranges.selection.startDate,ranges.selection.endDate);
  };
  return (
    <div className="date-filter">
      <DateRangePicker
        onChange={handleSelectRange}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        months={2}
        ranges={[
          {
            startDate: startDate,
            endDate: endDate,
            key: "selection",
          },
        ]}
        direction="horizontal"
      />
      ;
    </div>
  );
};
export default DateSelectorV1;
