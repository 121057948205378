import { lazy } from "react";

// project imports
import Loadable from "ui-component/Loadable";
import MinimalLayout from "layout/MinimalLayout";

// login option 3 routing
const Login = Loadable(lazy(() => import("pages/Login")));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const LoginRoutes = {
  path: "/",
  element: <MinimalLayout />,
  children: [
    {
      path: "/",
      element: <Login />,
    },
  ],
};

export default LoginRoutes;
