import PropTypes from "prop-types";
import { forwardRef, useState } from "react";
import MuiTypography from "@mui/material/Typography";
import { gridSpacing } from "store/constant";
// material-ui
import { useTheme } from "@mui/material/styles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Avatar,
  Button,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import SubCard from "./SubCard";
import MainCard from "./MainCard";

// ==============================|| CUSTOM SUB CARD ||============================== //

const DataCard = forwardRef(
  (
    {
      children,
      content,
      contentClass,
      darkTitle,
      secondary,
      sx = {},
      contentSX = {},
      title,
      value,
      ...others
    },
    ref,
  ) => {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };
    return (
      // <SubCard content={false} title={title} secondary={}>
      //     <Grid container direction="column" spacing={1}>
      //     <Grid item>
      //         <MuiTypography variant="h1" gutterBottom>
      //             {value}
      //         </MuiTypography>
      //         </Grid>
      //     </Grid>

      // </SubCard>
      <MainCard sx={{ textAlign: "center" }}>
        <Grid
          container
          spacing={gridSpacing}
          alignContent="center"
          justifyContent="space-between"
        >
          <Grid item xs={12}>
            <Typography variant="h4">{title}</Typography>
          </Grid>
          {/* <Grid item>
                    <MoreVertIcon
                      fontSize="small"
                      sx={{
                        color: theme.palette.primary[200],
                        cursor: 'pointer'
                      }}
                      aria-controls="menu-popular-card"
                      aria-haspopup="true"
                      onClick={handleClick}
                    />
                    <Menu
                      id="menu-popular-card"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                      variant="selectedMenu"
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                      }}
                    >
                      <MenuItem onClick={handleClose}> Remove</MenuItem>
                    </Menu>
                  </Grid> */}
          <Grid item xs={12} sx={{ pt: "16px !important" }}>
            <MuiTypography variant="h3" gutterBottom>
              {value}
            </MuiTypography>
          </Grid>
        </Grid>
      </MainCard>
    );
  },
);

DataCard.propTypes = {
  children: PropTypes.node,
  content: PropTypes.bool,
  contentClass: PropTypes.string,
  darkTitle: PropTypes.bool,
  secondary: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.object,
  ]),
  sx: PropTypes.object,
  contentSX: PropTypes.object,
  title: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.object,
  ]),
};

DataCard.defaultProps = {
  content: true,
};

export default DataCard;
