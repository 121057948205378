import axios from "axios";
import {
  FETCH_REQUEST,
  FETCH_ADVERTISER_LIST,
  FETCH_ERROR,
  FETCH_CAMPAIGN_LIST,
  SET_ADVERTISER,
  FETCH_CAMPAIGN_LIST_BY_ADVERTISER,
  SET_CAMPIGN_LIST_SELECTED,
  FETCH_ADVERTISER_REQUEST,
  FETCH_CAMPAIGN_ERROR,
  FETCH_ADVERTISER_ERROR
} from "./advertiserTypes";
import advertiserData from "assets/data/advertiser.json";

import campaignData from "assets/data/campaigns.json";
axios.defaults.timeout = 120000;
const timeout = 120000;

export const fetchFailure = (error) => {
  return {
    type: FETCH_ERROR,
    payload: [],
  };
};

export const fetchAdvertiserListFailure = (error) => {
  console.log("inside fetchAdvertiserListFailure===>");

  return {
    type: FETCH_ADVERTISER_ERROR,
    payload: [],
  };
};
export const fetchRequest = (msg) => {
  //console.log("fetchRequest-->")

  return {
    type: FETCH_REQUEST,
    payload: [],
  };
};
export const fetchCampaignError = (msg) => {
  console.log("fetchRequest-->", msg);

  return {
    type: FETCH_CAMPAIGN_ERROR,
    payload: msg,
  };
};
export const fetchAdvertiserData = (msg) => {
  console.log("fetching=====>")
  return {
    type: FETCH_ADVERTISER_REQUEST,
    payload: [],
  };
};
export const setFetchedAdvertiserList = (advertiser_list) => {
  console.log("advertiser_list", advertiser_list);
  return {
    type: FETCH_ADVERTISER_LIST,
    payload: {
      advertiser_list: advertiser_list.advertisers,
    },
  };
};

export const fetchAdvertiserList = (email) => (dispatch) => {
  //console.log(email)

  dispatch(fetchRequest);
  axios({
    method: "post",
    url: `${process.env.REACT_APP_INTERNAL_SERVER_URL}/getadvertiserListForUser`,
    header: {
      "Content-Type": "application/json",
    },
    data: {
      userEmail: email,
    },
    timeout: timeout
  })
    .then((response) => {
      //console.log("response advertiser", response.data.data.length);
      if (response.data.data.length > 0) {
        return dispatch(setFetchedAdvertiserList(response.data.data[0]));
      } else {
        //console.log("inside fetchAdvertiserListFailure");
        return dispatch(fetchAdvertiserListFailure(""));

      }
    })
    .catch(function (error) {
      //console.log("Show error notification!", error);
      return dispatch(fetchFailure("Not able to fetch data. Please retry"));
      //return Promise.reject(error)
    });
};

export const fetchCampaignsForAdvertiser =
  (advertiser_guid, sfid) => (dispatch) => {
   
    dispatch(fetchAdvertiserData(""));
    axios({
      method: "post",
      url: `${process.env.REACT_APP_INTERNAL_SERVER_URL}/getCampaignListForAdvertiser`,
      header: {
        "Content-Type": "application/json",
      },
      data: {
        AdvertiserId: advertiser_guid,
      },
      timeout: timeout
    })
      .then((response) => {
        // console.log("Activated----><<",response.data.data.length)
        if (response.data.data.length > 0) {
          // console.log(response.data.data)
          return dispatch(setFetchedCampaignList(response.data.data, sfid));
        } else {
          return dispatch(fetchCampaignError("Android Campaigns Not LIVE."));
        }
      })
      .catch(function (error) {
        // console.log(
        //   "Show error notification!",
        //   error,
        //   error.response.data.error,
        // );
        return dispatch(fetchFailure("Not able to fetch data. Please retry"));
        //return Promise.reject(error)
      });
  };
export const setFetchedCampaignList = (campaignData, sfId) => {
  //console.log("campaign_list",campaignData)
  return {
    type: FETCH_CAMPAIGN_LIST_BY_ADVERTISER,
    payload: {
      campaign_list: campaignData,
      // default_selected_campaigns: campaignData.map(
      //   (value) => value.campaign_guid,
      // ).slice(0,2),
      default_selected_campaigns: campaignData.map(
        (value) => value.campaign_guid),
      sfId: sfId,
    },
  };
};

export const fetchCampaignsByAdvertiserGuid = (advertiser_guid) => {
  let campaignDetailsForAdvertiser = campaignData.filter(
    (data) => data.advertiserID == advertiser_guid,
  );
  // const selected_Advertiser=getState().advertiserReducer.advertiser_guid;
  //console.log("Action",campaignDetailsForAdvertiser)
  return {
    type: FETCH_CAMPAIGN_LIST_BY_ADVERTISER,
    payload: campaignDetailsForAdvertiser,
  };
};
export const setSelectedAdvertiserData = (details) => {
  console.log(details)
  return {
    type: SET_ADVERTISER,
    payload: details,
  };
};
export const setSelectedAdvertiser =
  (advertiser_name, advertiser_guid) => (dispatch) => {
    console.log("setSelectedAdvertiser",advertiser_name,advertiser_guid)
    let data = {
      advertiser_guid: advertiser_guid,
      advertiser_name: advertiser_name,
    };
    dispatch(setSelectedAdvertiserData(data));
    //  return {
    //         type:SET_ADVERTISER,
    //         payload:data
    //  }
  };
// set selected campaign guids as selected by autocomplete
export const setSelectedCampaignList = (campaign_guids) => {
  console.log("campaign_guids",campaign_guids)
  return {
    type: SET_CAMPIGN_LIST_SELECTED,
    payload: campaign_guids,
  };
};
export const fetchSkanCampaignsForAdvertiser =
  (advertiser_guid, sfid) => (dispatch) => {
    dispatch(fetchRequest);
    //console.log("Activated---->")
    axios({
      method: "post",
      url: `${process.env.REACT_APP_INTERNAL_SERVER_URL}/getSkanCampaignListForAdvertiser`,
      header: {
        "Content-Type": "application/json",
      },
      data: {
        AdvertiserId: advertiser_guid,
      },
      timeout: timeout
    })
      .then((response) => {
        //console.log("Activated----><<", response.data.data.length);
        if (response.data.data.length > 0) {
          //console.log(response.data.data);
          return dispatch(setFetchedCampaignList(response.data.data, sfid));
        } else {
          //console.log("nside elsex");
          return dispatch(fetchCampaignError("iOS Campaigns Not LIVE."));
        }
      })
      .catch(function (error) {
        // console.log(
        //   "Show error notification!",
        //   error,
        //   error.response.data.error,
        // );
        return dispatch(fetchFailure("Not able to fetch data. Please retry"));
        //return Promise.reject(error)
      });
  };
