// assets
import { IconDashboard, IconBrandChrome, IconStack,IconReportAnalytics } from "@tabler/icons";

// constant
const icons = { IconDashboard, IconBrandChrome, IconStack, IconReportAnalytics };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
  id: "dashboard",
  type: "group",
  children: [
    {
      id: "default",
      title: "Overview",
      type: "item",
      url: "/dashboard",
      icon: icons.IconDashboard,
      breadcrumbs: false,
    },
    {
      id: "reporting",
      title: "Publisher App Report",
      type: "item",
      url: "/reporting",
      icon: icons.IconReportAnalytics,
      breadcrumbs: false,
    },
    {
      id: "creative",
      title: "Creative Report",
      type: "item",
      url: "/creative",
      icon: icons.IconStack,
      breadcrumbs: false,
    },
  ],
};

export default dashboard;
