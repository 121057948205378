import {
  FETCH_ADVERTISER_LIST,
  FETCH_REQUEST,
  FETCH_CAMPAIGN_LIST,
  FETCH_ERROR,
  SET_ADVERTISER,
  FETCH_CAMPAIGN_LIST_BY_ADVERTISER,
  SET_CAMPAIGN_LIST_FOR_REPORTING,
  SET_CAMPIGN_LIST_SELECTED,
  FETCH_ADVERTISER_REQUEST,
  FETCH_CAMPAIGN_ERROR,
  FETCH_ADVERTISER_ERROR,
} from "./advertiserTypes";

const initialState = {
  loading: false,

  advertiser_list: [],
  advertiser_guid: "",
  advertiser_name: "",
  campaign_list: [],
  adv_campaign_list: [],
  selected_campaigns: [],
  sfId: "",
  advertiser_campaign_fetching: false,
  campaign_list_error_msg: "",
  user_not_onboarded: false,
};

const advertiserReducer = (state = initialState, action) => {
  console.log("<><><<", action.type, action.payload);

  switch (action.type) {
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ADVERTISER_REQUEST: {
      return {
        ...state,
        advertiser_campaign_fetching: true,
        selected_campaigns:[],
        campaign_list:[]

      };
    }
    case FETCH_ADVERTISER_LIST:
      return {
        ...state,
        loading: false,
        advertiser_list: action.payload.advertiser_list,
        user_not_onboarded: false,
        advertiser_campaign_fetching:false
      };

    case FETCH_CAMPAIGN_LIST:
      return {
        ...state,
        loading: false,
        campaign_list: action.payload,
      };
    case FETCH_ERROR:
      return {
        ...state,
        loading: false,
        campaign_list: [],
      };
    case FETCH_ADVERTISER_ERROR:
      return {
        ...state,
        loading: false,
        advertiser_list: [],

        user_not_onboarded: true,
        msg: "User Not Onboarded"
      };
    case FETCH_CAMPAIGN_ERROR:
      return {
        ...state,
        loading: false,
        campaign_list: [],
        selected_campaigns: [],
        campaign_list_error_msg: action.payload,
        advertiser_campaign_fetching:false
      };
    case SET_ADVERTISER:
      return {
        ...state,
        advertiser_guid: action.payload.advertiser_guid,
        advertiser_name: action.payload.advertiser_name,
        sfId: action.payload.sfId,
        campaign_list_rest: true,
      };
    case FETCH_CAMPAIGN_LIST_BY_ADVERTISER:
      return {
        ...state,

        loading: false,
        campaign_list: action.payload.campaign_list,
        selected_campaigns: action.payload.default_selected_campaigns,
        campaign_list_rest: false,
        sfId: action.payload.sfId,
        advertiser_campaign_fetching: false,
        campaign_list_error_msg: "",
      };
    case SET_CAMPAIGN_LIST_FOR_REPORTING:
      return {
        ...state,
        selected_campaigns: action.payload,
      };
    case SET_CAMPIGN_LIST_SELECTED: {
      return {
        ...state,
        selected_campaigns: action.payload,
      };
    }
    default:
      return state;
  }
};
export default advertiserReducer;
