import React, { useState, useEffect } from "react";
import "./FullScreenLoader.css"; // Import your CSS file for styling

const LoaderCustom = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate a delay and then hide the loader
    // const timeout = setTimeout(() => {
    //   setIsLoading(false);
    // }, 2000); // Adjust the timeout duration as needed
    //return () => clearTimeout(timeout); // Cleanup on component unmount
  }, []);

  return (
    <div className={`fullscreen-loader ${isLoading ? "visible" : "hidden"}`}>
      <div className="loader-container">
        <div className="spinner"></div>
        <p>Loading...</p>
      </div>
    </div>
  );
};

export default LoaderCustom;
