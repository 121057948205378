import React, { useState, useEffect, lazy } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "react-redux";
import { Autocomplete, TextField, Box, Chip } from "@mui/material";
import LoaderCustom from "ui-component/LoaderCustom";
import {
  SET_ADVERTISER,
  FETCH_REQUEST,
} from "../../redux/advertiser/advertiserTypes";

import {
  fetchAdvertiserList,
  setSelectedAdvertiser,
  fetchCampaignsByAdvertiserGuid,
  fetchCampaignsForAdvertiser,
  fetchSkanCampaignsForAdvertiser,
} from "../../redux/advertiser/advertiserActions";

import {
  resetReportData
} from "../../redux/reporting/reportingActions"
import {
  resetCreativeData
} from "../../redux/creative/creativeActions"
import {fetchPerformanceRequestError,resetScreenData} from "../../redux/campaign/campaignActions"
const AdvertisersFilter = ({
  fetchAdvertiserList,
  advertiserList,
  advertiser_guid,
  advertiser_name,
  campaign_list,
  fetchCampaignsForAdvertiser,
  loading,
  login,
  userEmail,
  isSkan,
  fetchSkanCampaignsForAdvertiser,
  advertiser_campaign_fetching,
  performanceLoading,
  widzardLoading,
  reportLoading,
  fetchPerformanceRequestError,
  resetScreenData,
  resetReportData,
  resetCreativeData
}) => {
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState([""]);
  const [disableAdvertiserDropDown, setDisableAdvertiserDropDown] =
    React.useState(false);
  let advertiser_id = localStorage.getItem("advertiserId");
  //console.log(advertiser_id)

  const defaultProps = {
    options: advertiserList,
    getOptionLabel: (option) => option.title,
  };

  useEffect(() => {
    // Retrieve selected option from local storage on component mount
    const storedOption = localStorage.getItem("advertiserId");
    console.log("isSkan", isSkan);
    if (storedOption && advertiserList.length > 0) {
      let selectedAdvertiserData = advertiserList.filter(
        (option) => option.id == storedOption,
      );
      console.log(selectedAdvertiserData[0]);
      // localStorage.setItem('sfId',selectedAdvertiserData[0].sfId)
      setSelectedOption(selectedAdvertiserData[0]);
      let data = {
        advertiser_guid: selectedAdvertiserData[0].id,
        advertiser_name: selectedAdvertiserData[0].name,
        sfId: selectedAdvertiserData[0].sfId,
      };
      dispatch({ type: SET_ADVERTISER, payload: data });
      if (isSkan == true) {
        
        fetchSkanCampaignsForAdvertiser(
          selectedAdvertiserData[0].id,
          selectedAdvertiserData[0].sfId,
        );
      } else {
        fetchCampaignsForAdvertiser(
          selectedAdvertiserData[0].id,
          selectedAdvertiserData[0].sfId,
        );
      }
    }
  }, [advertiserList, isSkan]);
  const handleInputChange = (event, value) => {
    if (value != null) {
      setSelectedOption(value);
      dispatch({ type: FETCH_REQUEST, payload: "" });
      fetchPerformanceRequestError("")
      if (isSkan == true) {
        fetchSkanCampaignsForAdvertiser(value.id, value.sfId);
      } else {
        fetchCampaignsForAdvertiser(value.id, value.sfId);
      }
      localStorage.setItem("advertiserId", value.id);
      localStorage.setItem("sfId", value.sfId);

      let data = {
        advertiser_guid: value.id,
        advertiser_name: value.name,
        sfId: value.sfId,
      };
      dispatch({ type: SET_ADVERTISER, payload: data });
      resetScreenData();
      resetReportData();
      resetCreativeData()
      //let campaignDetailsForAdvertiser=campaign_list.filter(data=>data.advertiserID==advertiser_guid);
      // dispatch({ type: FETCH_CAMPAIGN_LIST_BY_ADVERTISER, payload:campaignDetailsForAdvertiser});
      //fetchCampaignsByAdvertiserGuid(value.advertiser_guid);
    }
  };

  useEffect(() => {
    if (login == true && advertiserList.length == 0) {
      fetchAdvertiserList(userEmail);
    }
  }, [login, advertiserList]);
  useEffect(() => {
    //console.log(selectedOption)
  }, [selectedOption]);
  useEffect(() => {
    //console.log("advertiser_guid",advertiser_guid)
  }, [advertiser_guid]);

  useEffect(() => {
    console.log(
      "advertiser_campaign_fetching",
      advertiser_campaign_fetching,
      "performanceLoading",
      performanceLoading,
      "widzardLoading",
      widzardLoading,
      "reportLoading",
      reportLoading,
    );
    // if(advertiser_campaign_fetching==true || performanceLoading==true || widzardLoading==true){
    //     setDisableAdvertiserDropDown(true)
    // }
    // else{
    //     setDisableAdvertiserDropDown(false)
    // }
  }, [advertiser_campaign_fetching, performanceLoading]);
  return (
    <>
      {/* <LoaderCustom show={loading}/> */}

      <Autocomplete
        value={selectedOption}
        disabled={disableAdvertiserDropDown}
        sx={{ zIndex: "999999" }}
        onChange={handleInputChange}
        options={advertiserList}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField {...params} label="Select Advertiser" variant="outlined" />
        )}
      />
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    loading: state.advertiserReducer.loading,
    advertiserList: state.advertiserReducer.advertiser_list,
    advertiser_guid: state.advertiserReducer.advertiser_guid,
    advertiser_name: state.advertiserReducer.advertiser_name,
    campaign_list: state.advertiserReducer.campaign_list,
    login: state.userReducer.login,
    userEmail: state.userReducer.userEmail,
    isSkan: state.campaignReducer.isSkan,
    advertiser_campaign_fetching:
      state.advertiserReducer.advertiser_campaign_fetching,
    performanceLoading: state.campaignReducer.performanceLoading,
    widzardLoading: state.campaignReducer.widzardLoading,
    reportLoading: state.campaignReducer.reportLoading,
  };
};
const mapDispatchToProps = {
  fetchAdvertiserList: fetchAdvertiserList,
  setSelectedAdvertiser: setSelectedAdvertiser,
  fetchCampaignsByAdvertiserGuid: fetchCampaignsByAdvertiserGuid,
  fetchCampaignsForAdvertiser: fetchCampaignsForAdvertiser,
  fetchSkanCampaignsForAdvertiser: fetchSkanCampaignsForAdvertiser,
  fetchPerformanceRequestError:fetchPerformanceRequestError,
  resetScreenData: resetScreenData,
  resetReportData: resetReportData,
  resetCreativeData: resetCreativeData
};
export default connect(mapStateToProps, mapDispatchToProps)(AdvertisersFilter);
