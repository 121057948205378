import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
// material-ui
import { Typography, Grid, Paper } from "@mui/material";
import { gridSpacing } from "store/constant";
import SearchBar from "components/Dashboard/SearchBar";
// project imports
import MainCard from "ui-component/cards/MainCard";
import { SET_SIDE_MENU } from "store/actions";
import ReportDataNonSkanView from "components/Reporting/ReportDataNonSkanView";
import ReportDataSkanView from "components/Reporting/ReportingDataSkanView";
import LoaderCustom from "ui-component/LoaderCustom";
import "./style.scss";
// ==============================|| Reporting PAGE ||============================== //

const Reporting = ({ isSkan,advertiserList, advertiser_campaign_fetching }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.setItem("showSideMenu", true);
    dispatch({ type: SET_SIDE_MENU, showSideMenu: true });
  }, []);
  console.log("advertiser_campaign_fetching",advertiser_campaign_fetching)
  return (
    <>
    {advertiserList.length == 0 || advertiser_campaign_fetching==true ? (
        <LoaderCustom />
      ) :(
   
    <>
      <Paper elevation spacing={gridSpacing} className="stickyBox">
        <SearchBar />
      </Paper>
      <Grid container spacing={gridSpacing}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Grid container spacing={gridSpacing}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              {isSkan == true ? (
                <ReportDataSkanView />
              ) : (
                <ReportDataNonSkanView />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>)}
     </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.advertiserReducer.loading,
    campaignList: state.advertiserReducer.campaign_list,
    advertiser_guid: state.advertiserReducer.advertiser_guid,
    advertiser_name: state.advertiserReducer.advertiser_name,
    selected_campaigns: state.campaignReducer.campaign_list,
    selected_campaigns_to_fetch_data:
    state.advertiserReducer.selected_campaigns,
    isSkan: state.campaignReducer.isSkan,
    userEmail: state.userReducer.userEmail,
    advertiserList: state.advertiserReducer.advertiser_list,
    advertiser_campaign_fetching:state.advertiserReducer.advertiser_campaign_fetching
  };
};
export default connect(mapStateToProps, null)(Reporting);
