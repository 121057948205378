import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation} from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import ReactGA from 'react-ga';
// routing
import Routes from "../routes";

// defaultTheme
import themes from "../themes";

// project imports
import NavigationScroll from "../layout/NavigationScroll";

// ==============================|| APP ||============================== //


function usePageViews() {
  let location = useLocation();
  useEffect(() => {
      ReactGA.pageview(location.pathname + location.search);
      ReactGA.event({
        category: 'User Interaction',
        action: 'Button Click',
        label: 'Button Label',
      });
      sendPageViewEvent()
  }, [location]);
}

const sendPageViewEvent = (title) => {
  const measurementId = 'G-7Q5GXC62NK';
  const apiUrl = `https://www.google-analytics.com/mp/collect?measurement_id=${measurementId}`;
  const clientId='df455';//CLIENT_ID
  fetch(apiUrl, {
    method: 'POST',
    body: JSON.stringify({
      client_id: clientId, // Replace with a unique client ID
      events: [
        {
          name: 'page_view',
          params: {
            page_title: title,
            page_location: window.location.href,
            page_path: window.location.pathname
          }
        }
      ]
    })
  })
  .then(response => {
    if (!response.ok) {
      throw new Error('Failed to send page view event');
    }
  })
  .catch(error => {
    console.error(error);
  });
};

const Master = () => {
  const customization = useSelector((state) => state.customization);
  usePageViews();


  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <CssBaseline />
        <NavigationScroll>
          <Routes />
        </NavigationScroll>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default Master;
